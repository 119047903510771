import * as React from "react";
import { graphql } from "gatsby";
import PageContent from "../../../components/layout/content";
import Layout from "../../../components/layout/layout";
import { useMemo } from "react";
import useLayoutGeneration from "../../../core/hooks/useLayoutGeneration";
import "../../../theme/style.scss";
import Seo from "../../../components/basics/seo/seo";
import AnimatedHeader from "../../../components/layout/header/animatedHeader/animatedHeader";
import {
  FrameBlock100,
  FrameSectionRow,
} from "../../../components/basics/frame/frameBlocks";
import ButtonContainer from "../../../components/basics/button/buttonContainer";
import StudyTest from "../../../components/studyCard/StudyTest";

const StudyMaterialPage = ({ data }) => {
  const pageData = useMemo(() => data.contentfulOldal, [data]);
  const contents = useMemo(
    () => pageData?.tartalomTpus?.tartalom || [],
    [pageData]
  );

  const [generator, resolver] = useLayoutGeneration();

  const generatedContent = useMemo(() => {
    const generatedList = generator(contents);
    const componentList = generatedList.map(resolver);
    return componentList;
  }, [contents, generator, resolver]);

  const studyCardData = useMemo(() => {
    const filteredData = contents?.map((content) =>
      content.ComponentContent.filter(
        (component) => component.internal.type === "Contentful2OszloposSor"
      )
    );
    return filteredData.flat();
  }, [contents]);

  return (
    <Layout>
      <Seo
        title={pageData.cm}
        description={pageData.alcm}
        url={
          pageData?.kategria?.slug
            ? `${pageData?.kategria?.slug}/${pageData.slug}`
            : pageData.slug
        }
      />
      <AnimatedHeader queryData={pageData?.tartalomTpus?.header} />
      <PageContent>
        {generatedContent}
        {studyCardData?.length > 0 && (
          <FrameSectionRow>
            <FrameBlock100>
              <h3>Still having questions?</h3>
              <p className="text-center">Try this interactive test</p>
              <ButtonContainer>
                <StudyTest studyData={studyCardData} />
              </ButtonContainer>
            </FrameBlock100>
          </FrameSectionRow>
        )}
      </PageContent>
    </Layout>
  );
};
//hozzá kell adni az összes építőblock típust
export const query = graphql`
  query general($contentfulId: String = "5RhJPUS5sgdSiK5H9fFWtw") {
    contentfulOldal(contentful_id: { eq: $contentfulId }) {
      slug
      cm
      alcm
      kategria {
        slug
      }
      tartalomTpus {
        ... on ContentfulStudyMaterialTartalomTipus {
          header {
            lers
            title
          }
          tartalom {
            ... on ContentfulSzekcio {
              contentful_id
              szekcicm {
                raw
              }
              ComponentContent: tartalom {
                ... on ContentfulYoutubeVideoBeagyazas {
                  contentful_id
                  yotubeVideoUrl
                  transparentImage
                  bortkp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                  internal {
                    type
                  }
                }
                ... on Contentful2OszloposSor {
                  cm
                  alcm
                  contentful_id
                  oszlopTartalom: tartalom {
                    raw
                  }
                  nagythatKp
                  kp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                  internal {
                    type
                  }
                }
              }
              internal {
                type
              }
            }
          }
        }
        __typename
      }
    }
  }
`;

export default StudyMaterialPage;

// query general($contentfulId: String) {
//   contentfulOldal(contentful_id: { eq: $contentfulId }) {
//     slug
//     cm
//     alcm
//     kategria {
//       slug
//     }
//     tartalomTpus {
//       ... on ContentfulStudyMaterialTartalomTipus {
//         header {
//           lers
//           title
//         }
//         tartalom {
//           ... on ContentfulStudySection {
//             contentful_id
//             cm {
//               raw
//             }
//             ComponentContent: tartalom {
//               ... on ContentfulStudyQuestion {
//                 contentful_id
//                 title
//                 type
//                 description
//                 bortkp {
//                   gatsbyImageData(layout: CONSTRAINED)
//                 }
//                 internal {
//                   type
//                 }
//               }
//               ... on ContentfulYoutubeVideoBeagyazas {
//                 contentful_id
//                 yotubeVideoUrl
//                 transparentImage
//                 bortkp {
//                   gatsbyImageData(layout: CONSTRAINED)
//                 }
//                 internal {
//                   type
//                 }
//               }
//             }
//             internal {
//               type
//             }
//           }
//         }
//       }
//       __typename
//     }
//   }
// }

// ... on ContentfulStudySection {
//   contentful_id
//   cm {
//     raw
//   }
//   ComponentContent: tartalom {
//     ... on  ContentfulStudyQuestion{
//       contentful_id
//       title
//       type
//       description
//       bortkp {
//         gatsbyImageData(layout: CONSTRAINED)
//       }
//       internal {
//         type
//       }
//     }
//     ... on ContentfulYoutubeVideoBeagyazas {
//       contentful_id
//       yotubeVideoUrl
//       transparentImage
//       bortkp {
//         gatsbyImageData(layout: CONSTRAINED)
//       }
//       internal {
//         type
//       }
//     }
//   }
//   internal {
//     type
//   }
// }
