import * as React from "react";
import "./StudyTest.scss";
import { Modal } from "../modal/modal";
import Button from "../basics/button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import InvisibleButton from "../basics/button/invisibleButton";
import { useState } from "react";
import { useCallback } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { useId } from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import richTextOptions from "../richTextOptions/richTextOptions";

const StudyTest = ({ studyData }) => {
  const [cardNumber, setCardNumber] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const changeNumber = useCallback(
    () =>
      studyData.length - 1 > cardNumber
        ? setCardNumber(cardNumber + 1)
        : setIsOpen(false),
    [cardNumber, studyData],
  );
  useEffect(() => {
    setCardNumber(0);
  }, []);

  const openModal = useCallback(() => {
    setIsOpen(true);
    setCardNumber(0);
  }, []);
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <Button onClick={openModal} type="block" text="Study Cards" />
      <Modal open={isOpen} setOpen={closeModal} triggerComponent={<></>}>
        <div className="study-test-container">
          <div className="study-test-blob"></div>
          <div className="study-card-container">
            <AnimatePresence>
              {studyData?.map(
                (data, id) =>
                  id === cardNumber && (
                    <motion.div
                      initial={{ opacity: 0, scale: 0 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{
                        position: "absolute",
                        opacity: 0,
                        translateY: "100%",
                      }}
                      key={data.contentful_id}
                      className={data.contentful_id}
                    >
                      <StudyCard
                        changeNumber={changeNumber}
                        question={data.cm}
                        answer={
                          data.oszlopTartalom &&
                          renderRichText(data.oszlopTartalom, richTextOptions)
                        }
                        type={data.alcm}
                      />
                    </motion.div>
                  ),
              )}
            </AnimatePresence>
          </div>
          <div className="progress-bar">
            <motion.div
              initial={{
                width: `${((cardNumber + 1) / studyData.length) * 100}%`,
              }}
              animate={{
                width: `${((cardNumber + 1) / studyData.length) * 100}%`,
              }}
              className="progress"
            ></motion.div>
          </div>
          <p className="text-center">
            Question {cardNumber + 1}/{studyData.length || 0}
          </p>
        </div>
      </Modal>
    </>
  );
};

const StudyCard = ({ changeNumber, question, answer, type }) => {
  const [flipped, setFlipped] = useState(false);
  const changeFlipped = useCallback(
    () => (!flipped ? setFlipped(!flipped) : changeNumber()),
    [flipped],
  );
  const questionId = useId();
  const answerId = useId();
  return (
    <InvisibleButton onClick={changeFlipped}>
      <div>
        <AnimatePresence initial={false}>
          {flipped ? (
            <motion.div
              key={answerId}
              initial={{ opacity: 1, rotateY: "90deg" }}
              animate={{ opacity: 1, rotateY: "0deg" }}
              exit={{ opacity: 1, rotateY: "-90deg" }}
              transition={{ ease: "linear", duration: 0.2, delay: 0.2 }}
            >
              <div className="study-card">
                <div className="study-card-wrapper">
                  <div className="test-type answer-type">
                    <p>{question}</p>
                  </div>
                  <div className="test-question">{answer}</div>
                  <p className="help-text">Tap for next question</p>
                </div>
              </div>
            </motion.div>
          ) : (
            <motion.div
              key={questionId}
              initial={{ opacity: 1, rotateY: "90deg" }}
              animate={{ opacity: 1, rotateY: "0deg" }}
              exit={{ opacity: 1, rotateY: "-90deg", position: "absolute" }}
              transition={{ ease: "linear", duration: 0.2 }}
            >
              <div className="study-card">
                <div className="study-card-wrapper">
                  <div className="test-type">
                    <FontAwesomeIcon icon={faGraduationCap} />
                    <p>{type}</p>
                  </div>
                  <p className="test-question">{question}</p>
                  <p className="help-text">Tap to reveal answer</p>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </InvisibleButton>
  );
};
export default StudyTest;
